// deps
import { useEffect } from "react";
import Head from "next/head";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import Router from "next/router";

// containers
import AuthLogin from "../containers/Auth/Login";

// hooks
import useAuth from "@raiden/library/hooks/useAuth";

export default function LoginPage(props) {
  const intl = useIntl();

  const { logged, loading, user } = useAuth();
  useEffect(
    function () {
      if (!loading && logged) {
        let redirect = Router.query?.next?.toString?.();
        if (redirect) {
          redirect = redirect.replace(
            process.env.NEXT_PUBLIC_ADMIN_BASE_PATH_CONFIG ?? "",
            "",
          );
          if (!redirect.startsWith("/")) {
            redirect = undefined;
          }
        }
        Router.push(redirect ?? "/dashboard");
      }
    },
    [logged, loading, user],
  );

  return (
    <>
      <Head>
        <title>
          {intl.formatMessage({
            id: "raiden.admin.pages.Login.head.title",
            defaultMessage: "Accéder à votre console",
          })}
        </title>

        <meta
          name="description"
          content={intl.formatMessage({
            id: "raiden.admin.pages.Login.head.description",
            defaultMessage: "Page de connexion à votre console.",
          })}
        />
      </Head>

      <AuthLogin />
    </>
  );
}

LoginPage.propTypes = {
  cookies: PropTypes.string,
};

LoginPage.getInitialProps = async function () {
  return {
    layout: "signedOut",
  };
};
