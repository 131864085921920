import { apiGetErrorDetail } from "../../../helpers/api";
import apiGetErrorFields from "../../../helpers/api/getErrorFields";

/**
 * Injects errors received from API into react-hook-form
 * @typedef {{[key:string]: string}} Error
 */
/**
 * @param {{
 * response: Response,
 * form: any,
 * setMessage: (message: string | null) => void,
 * setCaughtErrors: (errors: Error) => void,
 * setUncaughtErrors: (errors: Error) => void,
 * toastError: any,
 * intl: import("react-intl").IntlShape,
 * }} param0
 */
export default function resolveApiError({
  response,
  form,
  setMessage,
  setCaughtErrors,
  setUncaughtErrors,
  toastError,
  intl,
}) {
  const { setError, getValues } = form;

  /** @type {string} */
  const message = apiGetErrorDetail({ error: response });
  /** @type {Error} */
  const caughtErrors = {};
  /** @type {Error} */
  const uncaughtErrors = {};

  const validNames = getNames(getValues());

  const errorObject = apiGetErrorFields({ error: response });
  const errors = resolveApiErrors(errorObject);
  errors.forEach((error) => {
    if (validNames.includes(error.name)) {
      setError(error.name, { message: error.message }, { shouldFocus: true });
      caughtErrors[error.name] = error.message;
    } else {
      uncaughtErrors[error.name] = error.message;
    }
  });

  if (response.status === 500) {
    toastError({
      title: intl.formatMessage({ defaultMessage: "Erreur" }),
      description: intl.formatMessage({
        defaultMessage: "Une erreur est survenue, veuillez réessayer.",
      }),
      status: "error",
      isClosable: true,
    });
  }

  setCaughtErrors(caughtErrors);
  setUncaughtErrors(uncaughtErrors);
  setMessage(message);
}

/**
 * @param {any} entity
 * @param {string} currentPath
 * @param {{name: string, message: string}[]} errors
 */
function resolveApiErrors(entity, currentPath = "", errors = []) {
  getEntries(entity).forEach(([key, value]) => {
    if (Array.isArray(value) && typeof value[0] === "string") {
      errors.push({
        name: `${currentPath.length > 0 ? `${currentPath}.${key}` : key}`,
        message: value.join(" "),
      });
    } else if (typeof value === "object" && value !== null) {
      resolveApiErrors(
        entity[key],
        `${currentPath.length > 0 ? `${currentPath}.${key}` : key}`,
        errors,
      );
    }
  });

  return errors;
}

function getNames(entity, path = [], names = []) {
  getEntries(entity).forEach(([key, value]) => {
    if (typeof value === "object" && !key.startsWith?.("_") && value !== null) {
      getNames(value, [...path, key], names);
    } else {
      const fieldPath = [...path, key];
      const name = fieldPath.join(".");
      !name?.startsWith("_") && names.push(name);
    }
  });

  return names;
}

/**
 * return entries from any entity (ex: object or array)
 * @param {*} entity
 * @returns {[string, any][]}
 */
function getEntries(entity) {
  let entries = [];
  if (Array.isArray(entity)) {
    entries = entity.map((value, index) => [index, value]);
  } else if (typeof entity === "object" && entity !== null) {
    entries = Object.entries(entity);
  }
  return entries;
}
